/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.brand ion-img {
    margin: auto;
    display: block;
}

.candidate_name {
  text-transform: capitalize;
}

.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

ion-footer.filter-opened,
ion-footer.filter-closed {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .filter-closed {
    .col-filter {
      display: none;
    }
    .col-listing {
      display: block;
    }
  }

  .filter-opened {
    .col-filter {
      display: block;
    }

    .col-listing {
      display: none;
    }
  }

  ion-footer.filter-opened {
    display: block;
  }

  ion-footer.filter-closed {
    display: none;
  }
}

//@media (prefers-color-scheme: dark) {
//  button.days-btn p , h4 {
//    color: #fff !important;
//  }
//
//  button.days-btn.today{
//    background: grey!important;
//  }
//}

.clearfix {
  display: table;
  clear: both;
}

ion-content {
  --background: #F7F8FA;
  background: #F7F8FA;
  --padding-top: 24px;
}

ion-back-button {
  --background: #F7F8FA;
  background: #F7F8FA;
  --border-radius: 100%;
  border-radius: 100%;
  margin: 8px 0;
}

@media (max-width: 900px) {
  ion-header {
    //box-shadow: 0 10px 20px 0 rgb(0 0 0 / 5%);
    background: #fff;
    --background: #fff;

    ion-toolbar {
      //padding: 40px 0 13px 0 !important;

      ion-title {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 48px;
        letter-spacing: -0.5px;
        color: #23233D;
        //padding-left: 32px;
        //padding-right: 32px;
        align-items: center;
      }
    }
  }

  ion-toolbar {
    --padding-start: 24px;
  }

  ion-content {
    --ion-padding: 24px;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-top: 24px;
    --padding-bottom: 24px;
  }
}

.clear-header:after {
  display: none;
}

ion-header.add-border:after {
  display: inline;
}

ng5-slider {
  $handleSize: 12px;

  background: var(--ion-custom-color-1) !important;
  margin: 10px 0 15px !important;

  .ng5-slider-pointer {
      width: 12px !important;
      height: 12px !important;
      top: -4px !important;
      background-color: var(--ion-color-primary) !important;
      outline: none;

      &:after {
          content: none;
          display: none;
      }
  }

  .ng5-slider-bubble {
      display: none !important;
  }
}

body.day .ng5-slider-selection {
  background: var(--ion-custom-color-45) !important;
}

ion-modal {
  ion-router-outlet {
    ion-header ion-buttons[slot="end"] ion-button {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--ion-color-primary-contrast);
    }
  }
}

body.day ion-router-outlet  ion-header {
  background: var(--ion-color-primary-contrast);
}

ion-router-outlet {
  ion-header ion-buttons[slot="end"] ion-button {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;
  }

  //ion-toolbar,
  //ion-content {
  //    --background: #fafafa;
  //}

  ion-title,
  ion-header {
      //padding-left: 10px;
      //padding-right: 10px;
      .ion-activatable {
        color: var(--ion-color-light-contrast);
      }
  }
  

  ion-title:not([size="small"]) {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--ion-color-light-contrast);
  }

  /*ion-content {
    //--padding-start: 30px;
    //--padding-end: 30px;
    --padding-start: 10px;
    --padding-end: 10px;
  }*/
}

ion-header {
  //--background: #fafafa;
  //background: #fafafa;
}

ion-footer .max-width,
ion-content .max-width,
ion-toolbar.max-width, 
ion-footer .desktop-view,
ion-content .desktop-view,
ion-toolbar.desktop-view {
  max-width: 787px;
  margin: 0 auto;
}

body.day ion-footer {  
  background-color: var(--ion-color-primary-contrast);
}

ion-footer {
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 9px;

  ion-button {
    height: 50px;
    margin: auto !important;
    display: block;
    border-radius: 4px !important;
    --border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-transform: none;
    --box-shadow: none;
  }

  ion-button[disabled] {
      background: var(--ion-custom-color-1);
      --color: var(--ion-color-primary-contrast);
      --opacity: 1;
      --background: var(--ion-custom-color-1);
      --background-focused: var(--ion-custom-color-1);
      --background-hover: var(--ion-custom-color-1);
      border-radius: 4px;
      --border-radius: 4px;
  }

  .button-disabled {
      background-color: var(--ion-custom-color-1);
      --background: var(--ion-custom-color-1);
      --background-focused: var(--ion-custom-color-1);
      --background-hover: var(--ion-custom-color-1);
  }
}

.empty-data{
  text-align: center;
  margin-top:30px;

  ion-icon{font-size:60px;}
  h2{margin-top:0;}
}

.split-pane-md.split-pane-visible > .split-pane-side {
  min-width: 239px;
  max-width: 19%;
}

.split-pane-visible > .split-pane-side {
  order: -1 !important;
}

.split-pane-side ion-router-outlet ion-toolbar,
.split-pane-side ion-router-outlet ion-content{
  --background: var(--ion-color-primary-contrast);
}

.main-right-menu,
.main-left-menu {
    padding: 26px 0 0 0;
}

/*ion-segment {
  margin-top: 16px;
}*/

ion-segment-button {
  text-transform: inherit;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--ion-custom-color-52);
  --color: var(--ion-custom-color-52);
}

ion-segment-button.segment-button-checked {
  font-weight: 600;
  color: var(--ion-custom-color-51);
  --color: var(--ion-custom-color-51);
  --indicator-color: var(--ion-custom-color-51);
  --border-color: var(--ion-custom-color-51);
}

.modal-sheet,
.footer-modal {
  --max-width: 800px;
  --border-radius: 30px;
}
 
.approve-work-log-modal {
  --height: 865px;
} 

.reject-work-log-modal {
  --height: 842px;
}

[dir="rtl"] {
  ion-back-button[icon="assets/images/icon-back.svg"] {
    transform: rotate(180deg) !important;
  }
}